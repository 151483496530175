import React from 'react';
import './progressbar.css'; // Make sure to create this CSS file

const ProgressBar = () => {
  return (
    <div className="progress-bar-background">
      <div className="progress-bar-fill"></div>
    </div>
  );
};

// const ProgressBar = ({ progress }) => {
//   return (
//     <div className="progress-bar-background">
//       <div className="progress-bar-fill" style={{ width: `${progress}%` }}></div>
//     </div>
//   );
// };

export default ProgressBar;
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {getStorage} from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyBi6Yyh2zhoZn30RtaOBXT7ghnxsxpVVCY",
  authDomain: "essay-grader-cec50.firebaseapp.com",
  projectId: "essay-grader-cec50",
  storageBucket: "essay-grader-cec50.appspot.com",
  messagingSenderId: "960854862298",
  appId: "1:960854862298:web:0f05da0475bc1ae2ed78e3",
  measurementId: "G-1XCVF09ZM1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Main.css';
import TLLLogo from '../assets/tll-logo.png';
import rightArrow from '../assets/right-arrow.svg';
import backArrow from '../assets/back-arrow.svg';
import userIcon from '../assets/user-icon.png';
import gptImgLogo from '../assets/bot-icon.png';
import { marked } from 'marked';
import { auth } from "../firebase";
import DOMPurify from 'dompurify';
import ProgressBar from '../progressbar.js';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

function Main() {
  const fileType = ['application/pdf'];
  const [messages, setMessages] = useState([{ text: 'Upload an essay for assessment.', isBot: true }]);
  const [fileName, setFileName] = useState('');
  const [pdfFile, setPDFFile] = useState(null);
  const [viewPDF, setViewPDF] = useState(null);
  const [SideBarVisible, setSideBarVisible] = useState(true);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const viewerRef = useRef(null);

  const Navigate = useNavigate();
  
  const toggleSideBar = () => {
    setSideBarVisible(!SideBarVisible);
  };

  const logout = async () => {
    try {
      await auth.signOut();
      console.log('Logged out successfully!');
      Navigate('/');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const newplugin = defaultLayoutPlugin();

    const handleAnalyseClick = async (e) => {
      setFileName(null);
      setViewPDF(null);
      setShowProgressBar(true);
  
      const formData = new FormData();
      // Assuming the file is stored in a state variable when selected
      formData.append('file', pdfFile); // Ensure 'pdfFile' state holds the file to upload
  
      try {
          const response = await fetch('http://localhost:4000/GoogleVision/upload', {
              method: 'POST',
              body: formData,
          });
  
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
  
          // Assuming the response includes the file URL or direct file blob
          const fileBlob = await response.blob();
  
          const fileURL = URL.createObjectURL(fileBlob);
  
          // Log the file URL
          console.log('File URL:', fileURL);
          setViewPDF(fileURL); // Setting the processed PDF URL for rendering
          setShowProgressBar(false);
      } catch (error) {
          console.error("Error processing file:", error);
          setMessages(prev => [...prev, { text: 'Error processing file.', isBot: true }]);
      }
  }  

  const uploadFile = async (e) => {
    setPDFFile(null);
    const file = e.target.files[0];
    if (!file || !fileType.includes(file.type)) return;
  
    setFileName(file.name);
    setPDFFile(file);
  };
  
  function formatMessageText(text) {
    if (text.includes('**') || text.includes('```')) {
      const html = marked.parse(text);
      let sanitizedHtml = DOMPurify.sanitize(html);
      return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
    } else {
      return text.split('\n').map((line, index) => (
        <span key={index}>
          {line}
          <br />
        </span>
      ));
    }
  }

  const [activeFocuses, setActiveFocuses] = useState({});
  const toggleActiveState = (setActive, category, name) => {
    setActive(prevActive => ({
      ...prevActive,
      [name]: !prevActive[name]
    }));
  };

  const [activeClass, setActiveClass] = useState(null);
  const [activeStrictness, setActiveStrictness] = useState(null);
  const handleClassClick = (className) => setActiveClass(className);
  const handleStrictnessClick = (strictness) => setActiveStrictness(strictness);

  const BackArrowComponent = () => (
    <div onClick={toggleSideBar} style={{ cursor: 'pointer' }}>
      <img src={backArrow} alt="Hide Sidebar" className="backArrow" />
    </div>
  );

  // Website design below
  return (
    <div className="App">
      {!SideBarVisible && (
        <span>
          <img
            src={rightArrow}
            alt="Show Sidebar"
            className="rightArrow"
            onClick={toggleSideBar}
          />
        </span>
      )}
      <div className={`sideBar ${SideBarVisible ? '' : 'hidden'}`}>
        <div className="upperSide">
          <div className="upperSideTop">
            <BackArrowComponent />
            <img src={TLLLogo} alt="Logo" className="logo" style={{ width: '210px', height: 'auto', margin: '0 auto' }} />
          </div>
          <input
            type="file"
            id="fileInput"
            onChange={uploadFile}
            style={{
              display: 'none',
              backgroundColor: '#ab995c',
              color: fileName ? 'black' : 'white',
              border: '1px solid #ab995c',
              borderRadius: '5px',
              fontSize: '16px',
              cursor: 'pointer',
            }}
            multiple={false}  // Ensures only one file can be selected
          />
          <label htmlFor="fileInput" className="custom-file-input">
            {fileName ? fileName : 'Choose File'}
          </label>
          <style jsx>{`
            .custom-file-input {
              display: inline-block;
              padding: 15px 0px;
              background-color: #ab995c;
              color: ${fileName ? 'black' : 'white'};
              border: 1px solid #ab995c;
              border-radius: 5px;
              font-size: 16px;
              cursor: pointer;
              width: 100%;
              text-align: center;
            }

            .custom-file-input:hover {
              background-color: #91764c;
              border-color: #91764c;
            }

            .custom-file-input:active {
              background-color: #7e653f;
              border-color: #7e653f;
            }
          `}</style>
          <div>
            <button className="submit-button sideBar-button" type="button" onClick={handleAnalyseClick}>
              Analyse
            </button>
          </div>
          <div className="filter-sidebar">
            <div className="filter-header">
              <h2>Grading Parameters</h2>
            </div>
            <div className="filter-text-instruction">
              <input type="text" placeholder="Custom Instructions" />
            </div>
            <div className="filter-category student-name">
              <h3>Student Name</h3>
              <input type="text" placeholder="Name" />
            </div>
            <div className="filter-category">
              <h3>Classes</h3>
              <div className="button-row">
                {['1A', '1B', '2A', '2B', '3C', '3E'].map((className) => (
                  <button
                    key={className}
                    className={`class-btn ${activeClass === className ? 'active' : 'inactive'}`}
                    onClick={() => handleClassClick(className)}
                  >
                    {className}
                  </button>
                ))}
              </div>
            </div>
            <div className="filter-category">
              <h3>Grading Focuses</h3>
              <div className="button-row">
                {['Grammar', 'Spelling', 'Tone'].map((focus) => (
                  <button
                    key={focus}
                    className={`category-btn ${activeFocuses[focus] ? 'active' : 'inactive'}`}
                    onClick={() => toggleActiveState(setActiveFocuses, 'focuses', focus)}
                  >
                    {focus}
                  </button>
                ))}
              </div>
              <div className="button-row">
                {['Topic', 'Uniqueness', 'PEEL'].map((focus) => (
                  <button
                    key={focus}
                    className={`category-btn ${activeFocuses[focus] ? 'active' : 'inactive'}`}
                    onClick={() => toggleActiveState(setActiveFocuses, 'focuses', focus)}
                  >
                    {focus}
                  </button>
                ))}
              </div>
            </div>
            <div className="filter-sort">
              <h3>Grading Strictness</h3>
              <div className="button-row">
                {['Easy', 'Default', 'Harsh'].map((strictness) => (
                  <button
                    key={strictness}
                    className={`sort-btn ${activeStrictness === strictness ? 'active' : 'inactive'}`}
                    onClick={() => handleStrictnessClick(strictness)}
                  >
                    {strictness}
                  </button>
                ))}
              </div>
            </div>
          </div>
          <button className="batch-grading-button sideBar-button" type="button">
            Batch Grading
          </button>
          <button
            className="teacher-dashboard-button sideBar-button"
            type="button"
            onClick={() =>
              window.open(
                'https://www.figma.com/proto/SQAKT85oN3b47r7KTRvIyx/Charts-UI-Kit-(Community)?type=design&node-id=1-4&t=K3Cxrins4sekUNmD-1&scaling=scale-down-width&page-id=1%3A3&mode=design',
                '_blank'
              )
            }
          >
            Teacher Dashboard
          </button>
          <button 
          className="logout-btn sideBar-button"
          type="button"
          onClick={logout}>
            Log Out
          </button>
          <div className="upperSideBottom"></div>
        </div>
      </div>
      <div className={`main ${SideBarVisible ? 'shifted' : 'fullWidth'}`}>     
      {viewPDF ? (
  <div className="pdf-viewer-with-download-top">
    <div className="pdf-viewer-container">
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
        <Viewer fileUrl={viewPDF} plugins={[newplugin]} />
      </Worker>
    </div>
    <button className="download-button" onClick={() => window.open(viewPDF)}>
      Download PDF
    </button>
  </div>

) : (
  <div className="chats">
    {messages.map((message, i) => (
      <div key={i} className={message.isBot ? 'chat bot' : 'chat'}>
        <img className="chatImg" src={message.isBot ? gptImgLogo : userIcon} alt="" />
        <p className="txt">{formatMessageText(message.text)}</p>
      </div>
    ))}
    {showProgressBar && <ProgressBar />}
  </div>
)}

      </div>
    </div>
  );
}

export default Main;

import React from "react";
import { onAuthStateChanged } from "firebase/auth";
import { ProtectedRoute} from "./components/protectedRoute";
import {auth} from "./firebase";
import './App.css';
import Login from "./pages/Login";
import Main from './pages/Main';
import {useState, useEffect} from "react";
import { Route, Routes } from "react-router-dom";

function App() {
  const [user, setUser] = useState(null)
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth,(user) => {
      if (user){
        setUser(user);
        return;
      }
      setUser(null);
    });
    return () => unsubscribe();
  }, [])
  return (
    <div >
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/Main" element={<ProtectedRoute user = {user}><Main /></ProtectedRoute>} />
      </Routes>
    </div>
  );
}

export default App;
